@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");
@import "react-toastify/dist/ReactToastify.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "onest-regular", "pp-migra", sans-serif !important;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden !important;
  /* -ms-overflow-style: none; */
  overflow-y: scroll;
  background-color: white !important;
}
.font-ppmigra {
  font-family: "pp-migra" !important;
}
.font-onestRegular {
  font-family: "onest-regular" !important;
}
.font-onestBold {
  font-family: "onest-bold" !important;
}
.font-onestMedium {
  font-family: "onest-medium" !important;
}
/* ONEST */
@font-face {
  font-family: "onest-regular";
  src: url("./asset/font/Onest/TTF/OnestRegular1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-bold";
  src: url("./asset/font/Onest/TTF/OnestBold1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-medium";
  src: url("./asset/font/Onest/TTF/OnestMedium1602-hint.ttf");
  font-display: swap;
}

@font-face {
  font-family: "onest-black";
  src: url("./asset/font/Onest/TTF/OnestBlack1602-hint.ttf");
  font-display: swap;
}

/* PP MIGRA */
@font-face {
  font-family: "pp-migra";
  src: url("/src/asset/font/PP Migra/PPMigra-Bold.ttf");
  font-display: swap;
}

/* ::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  border-radius: 10px;
  background-color: red;
  position: absolute;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px !important;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px !important;
  height: 4px !important;
  display: block;
  width: 3px !important;
  background-color: blue;
} */

.toast-message {
  background: #F1FFEF !important;
  color: #161718 !important;
  font-size: 14px;
  border-bottom: 3px #18AF04 !important;
  padding: 30px 20px;
}
.toast-success {
  background: rgb(243, 255, 243) !important;
  border-bottom: 3px #18AF04 !important;
}
.toast-error {
  background: rgb(252, 243, 243) !important;
  border-bottom: 3px red !important;
}
.toast-info {
  background: rgb(199, 199, 246) !important;
}
.Toastify__progress-bar--success {
  background-color: green !important;
}

.Toastify__progress-bar--info {
  background-color: blue !important;
}

.Toastify__progress-bar--warning {
  background-color: orange !important;
}

#formattedText {
  white-space: pre-line;
}

#wrapper {
  text-align: center;
  width: 500px;
  margin: auto;
}

.react-datepicker-wrapper {
  width: 100%;
}
.animate-faster.animate__fadeInUp {
  animation-duration: 0.4s;
}


/* [data-scrollbar] {
  overflow: hidden;
  background-color: "";
} */

/* [data-scrollbar] .scrollbar-track.scrollbar-track-y {
  z-index: 101;
  background: var(--deep-dark);
} */

/* [data-scrollbar] .scrollbar-track.scrollbar-track-y .scrollbar-thumb-y {
  background: rgb(186, 184, 184);
  
} */